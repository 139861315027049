
.product-item.tile {
    width 256px
    max-width: 256px;
    text-align center

    @media screen and (min-width: 768px) {
        width 291px
        max-width: 291px;
    }

    .product-item-image-container {
        background: #fff

        width: 100%
        max-width: 256px;
        height fit-content
        border-radius: 8px
        border 1px solid #7d7d7d

        margin-bottom 8px

        overflow hidden

        &::before {
            float: left;
            padding-top: 100%;
            content: '';
        }

        &::after {
            display: block;
            content: '';
            clear: both;
        }

        @media screen and (min-width: 768px) {
            max-width: 291px;
        }
    }

    .product-item-image-container .product-item-image {
        width 100% !important
        height auto !important
        max-height 100% !important
        max-height 100%
        object-position: center
        object-fit: contain
        border-radius: 8px
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.03);
			transition: all 0.5s ease;
        }
    }

    .product-item-name {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25012rem;
        margin: 24px 0 0;
        padding-top 0
        height: 54px // + 14px (badge)

        @media screen and (min-width: 1024px) {
            height: 44px
            color: #000;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 24px 0 0;
            overflow: hidden;
            word-break: break-all;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.25012rem;
        }
    }

    .product-item-price {
        margin-top: 4px
        height: 40px
        display: flex
        flex-direction: column
        justify-content: flex-end

        @media screen and (min-width: 1024px) {
            margin-top: 4px;

        }

        .price {
            font-family: "SF Pro Text";
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            color: #121212;
        }

        .price-srp {
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25012rem;

            text-decoration: line-through;
            color: #7d7d7d;
        }

        .discount-percent {
            font-family: "SF Pro Text";
            font-size: .75rem;
            font-style: normal;
            font-weight: 600;
            line-height: .875rem;
            color: #e30000;
        }
    }

    .product-item-action {
        display: flex
        justify-content: flex-end;
        align-items: flex-end;
        gap: 16px;
        margin-top 8px
        height 74px

        @media screen and (min-width: 768px) {
            justify-content: center;
            align-items: center;
        }

        ::v-deep .btn-add-product {
            width 100% !important
            height 48px !important
            max-width 100% !important
            max-height 48px !important
            padding 0 !important

            @media screen and (min-width: 768px) {
                width 176px !important
                height 48px !important
                max-width 176px !important
                max-height 48px !important
            }
        }
    }
}

.product-item.default {
    width 100%
    text-align center

    .product-item-image-container {
        width: 100%;
        height: 100%;
        max-height: 280px
        aspect-ratio: 1 / 1;
        overflow: hidden;
        background: #fff
        border-radius: 16px

        @media screen and (min-width: 1024px) {
            border-radius: 0
        }

        img {
            width: 100%;
            height: 100%;
            max-height: 280px;
            object-fit: contain;
            object-position: center;
        }
    }

    .product-item-name {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25012rem;
        padding-top: 8px;

        @media screen and (min-width: 1024px) {
            height: 44px
            color: #000;
            padding-top: .25rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0;
            overflow: hidden;
            word-break: break-all;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.25012rem;
            padding-top: 8px;
        }
    }

    .product-item-price {
        margin-top: 4px

        @media screen and (min-width: 1024px) {
            margin-top: 22px;

        }

        .price {
            color: #121212;
            padding-top: .25rem;
            font-family: "SF Pro Text";
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25012rem;
        }

        .price-srp {
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25012rem;

            text-decoration: line-through;
            color: #7d7d7d;
        }
    }

    .product-item-action {
        display: flex;
        justify-content: center;

        .product-item-action-checkbox {

            display flex
            align-items center
            gap 8px
            height 32px
            padding 8px 0
            cursor pointer

            &.is-selected {
                .product-item-action-checkbox-checkmark {
                    &::after {
                        border-color $color-bnn;
                    }
                }
            }

            .product-item-action-checkbox-checkmark {
                position: relative;
                height: 16px !important;
                min-height: 16px !important;
                width: 16px !important;
                min-width: 16px !important;
                background-color: #fff;
                border: 1px solid #121212;
                border-radius: 2px;
                padding: 3px;

                &::after {
                    content: "";
                    position: absolute;
                    border: solid #fff;
                    border-width: 0 0 2px 2px;
                    -webkit-transform: rotate(310deg);
                    -ms-transform: rotate(310deg);
                    transform: rotate(310deg);
                    left: 2px;
                    top: 2px;
                    width: 10px;
                    height: 7px;
                }
            }

            .label {
                color: rgba(18, 18, 18, .75);
                font-family: "SF Pro Text";
                font-size: .75rem;
                font-style: normal;
                font-weight: 600;
                line-height: .875rem;
            }
        }
    }
}
